/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components';
import { useModal } from '../../src/utils/globalModal';
import { SafeGuardModal } from '../modals/safeguard';
import { ReactComponent as UkraineFlag } from '../common/nav/ukraine-flag-icon.svg';
import { ReactComponent as Earnings } from '../common/nav/earnings.svg';


// Change the HEX code of background/text color here
// MUST BE FULL 6 DIGIT HEX FOR IT TO WORK
export const backgroundColor = '#000';
export const textColor = '#fff;';
export const backgroundGradient =
	'linear-gradient(#000);';

// Here edit the JSX (HTML) For alert
// To not show any alert, uncomment this line
// export const Alert = null;
// and comment everything below

const EarningsIcon = styled.div`
	display: inline-block;
	width: 20px;
	height: 20px;
	margin-left: 5px;
`;
export const Alert = () => {
	const [open, openModal, closeModal] = useModal();

	return (
		<>
			<strong>How to hire</strong> a pro —{' '}
			<a
				href="#"
				onClick={e => {
					e.preventDefault();
					openModal();
				}}
			>
				3 easy steps
			</a>
			<EarningsIcon>
				<Earnings />
			</EarningsIcon>
			<SafeGuardModal open={open} closeModal={closeModal} />
		</>
	);
};

