import React from 'react';

const whatIsHostjaneData = {
	title: 'What is HostJane?',
	subtitle: 'Read about our mission and values',
	cards: [
		{
			title: 'Better workspace',
			description:
			'Say hello to HostJane, a privacy-first platform where you only pay for work you approve. From hiring coders to white-labeled servicing, use Jane\'s Trello-like workspace to deliver work that changes the tone.',
		},
		{
			title: 'Independent pros',
			description:
				'Top freelancers often don\'t use generic, hyped marketplaces. Their side hustle is limited by time, offline careers, and employment contracts. HostJane provides a route for them to add value to your project.',
		},
		{
			title: 'Moving SMEs to AWS',
			description:
				'Power your website\'s growth with Amazon Web Services (AWS), the global cloud platform you won\'t outgrow. HostJane manages AWS servers for busy entrepreneurs and startups.',	
		},
	],
	backgroundImages: [
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="200"
			height="300"
			ariaHidden="true"
			viewBox="0 0 200 300"
		>
			<defs>
				<clipPath id="what-is-hostjane-left-blue-light-ClipPath1019">
					<path
						fill="#00f"
						d="M.9 94.3s-5.7-39.7 39.7-59.5c18.7-4.6 78.4-14 124.4 26.8 37.4 32.9 41.7 44.7 24.9 78.4-6.6 11.4-24.8 29.7-81.8 29.7-33.2-.6-64.8-12.9-78.5-25.8C10.2 129.4 2.9 113.4.9 94.3"
					/>
				</clipPath>
			</defs>
			<g transform="translate(-99 100)">
				<image
					width="200"
					height="200"
					clipPath='url("#what-is-hostjane-left-blue-light-ClipPath1019")'
					transform="rotate(45) scale(1.5)"
					xlinkHref="https://img0.hostjanestatic.com/site-assets/brand-refresh/textures/custom/what_is_hostjane_blue_light2.jpg"
					style={{
						WebkitTransformOrigin: 150,
						MsTransformOrigin: 150,
						transformOrigin: 150,
					}}
				/>
			</g>
		</svg>,
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="200"
			height="200"
			ariaHidden="true"
			viewBox="0 0 200 200"
		>
			<defs>
				<clipPath id="what-is-hostjane-right-top-ClipPath1033">
					<path
						fill="#faa129"
						d="M126.4 161c-3-3.7-9-12.5-9-12.5L114 141s-4-12.4-4.3-17.1c-.6-9.3 1.9-16 5.6-24.5 7.2-16.3 21.8-27.1 40.5-31.8l3.8-1.5 1.2-1.4-14.5-48.9-4.9-12.1c-2.9.5-8.4 2.9-11.1 3.6-12.1 3.2-22 8.6-32.1 13.9-11.2 6.6-28.9 24-28.9 24S52.8 62.3 47.8 74.8c-1.6 4.7-3 9.5-4 14.3-1.8 6.9-3.1 22.5-4.4 29.9-1.4 8 2.8 27.8 2.8 27.8s3 11.6 5.7 18.7c4.4 11.3 10.8 21.8 18.9 30.9 0 0 24.3-12.4 26.2-13.9s16.4-9 17.6-9.6c1.2-.6 12.6-4.4 12.6-4.4l6-2.8c.2-.3.3-.7.4-1.1 0-.2-3.1-3.6-3.2-3.6"
					/>
				</clipPath>
			</defs>
			<g>
				<image
					width="200"
					height="200"
					clipPath='url("#what-is-hostjane-right-top-ClipPath1033")'
					xlinkHref="https://img0.hostjanestatic.com/site-assets/brand-refresh/textures/custom/what_is_hostjane_yellow.jpg"
					style={{
						WebkitTransformOrigin: 100,
						MsTransformOrigin: 100,
						transformOrigin: 100,
					}}
				/>
			</g>
		</svg>,
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="300"
			height="200"
			ariaHidden="true"
			viewBox="0 0 300 200"
		>
			<defs>
				<clipPath id="what-is-hostjane-right-bottom-ClipPath1045">
					<path
						fill="#f1641e"
						d="M-1 46.5l8.6 52.6 9.9 59.8 72.9-6 106.4-7.3 4.1-47L201 41 95 43l-96 3.5z"
					/>
				</clipPath>
			</defs>
			<g transform="translate(100 100)">
				<image
					width="200"
					height="200"
					clipPath='url("#what-is-hostjane-right-bottom-ClipPath1045")'
					transform="rotate(-10) scale(1.4)"
					xlinkHref="https://img0.hostjanestatic.com/site-assets/brand-refresh/textures/custom/what_is_hostjane_orange2.jpg"
					style={{
						WebkitTransformOrigin: 140,
						MsTransformOrigin: 140,
						transformOrigin: 140,
					}}
				/>
			</g>
		</svg>,
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="200"
			height="200"
			ariaHidden="true"
			viewBox="0 0 200 200"
		>
			<defs>
				<clipPath id="what-is-hostjane-left-blue-dark-ClipPath1059">
					<path
						fill="#232347"
						d="M192.9 74.8c-2.1-5.3-3.9-10.8-5.3-16.4-3.8-16.9-24.3-38.6-33.2-46.7-8.9-8.1-26.8-9.5-38.6-7.5-22.2 3.6-44.4 4.8-63.9 17-4.6 2.9-24.2 17.8-29.2 22.1-12 10.4-20.2 22.8-20.5 40.3-1 8.3 3.3 17.4 2.7 26.4-1.6 26.1 13.6 45.1 26.8 65 7.5 11.3 60.8 22.8 70.8 21.6 14.7-1.8 29.1-5.4 42.8-10.9 11.2-4.2 20.5-12.4 26.1-23.1 1.8-3 3.8-5.9 6.2-8.5 19.6-23.7 25.8-49.9 15.5-79.3"
					/>
				</clipPath>
			</defs>
			<g transform="translate(50 20)">
				<image
					width="200"
					height="200"
					clipPath='url("#what-is-hostjane-left-blue-dark-ClipPath1059")'
					transform="scale(.3)"
					xlinkHref="https://img0.hostjanestatic.com/site-assets/brand-refresh/textures/custom/what_is_hostjane_blue2.jpg"
					style={{
						WebkitTransformOrigin: 100,
						MsTransformOrigin: 100,
						transformOrigin: 100,
					}}
				/>
			</g>
		</svg>,
	],
	bottomText: <em>Need a faster website with stable uptime? Your answer is AWS.</em>,
	bottomBtnText: 'View AWS hosting plans',
	bottomBtnLink: 'https://cloud.hostjane.com',
};

export default whatIsHostjaneData;
