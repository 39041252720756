import React, { useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import HostJaneSlide from './HostJaneSlide';
import { ReactComponent as ArrowRight } from '../../svgs/icons/arrow.svg';
import { ReactComponent as ArrowLeft } from '../../svgs/icons/arrow-left.svg';
import HostJane from '../../../static/HostJane.png';

const HostJaneSliderWrapper = styled.section`
	&.hostjane-main__HostJaneSlider {
		overflow: hidden;
		max-width: 1140px;
		text-align: center
		width: 100%;
    padding-right: 15px;
    padding-left: 15px;
		margin: 60px auto;
		.hostjane-main__HostJaneSlider__content {

			max-width: 50%;
			@media (max-width: 992px) {
				max-width: 80%;
			}
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			div {
				text-align: center;
				h3 {
					margin: 0;
					font-size: 40px;
					margin-bottom: 0;
					font-weight: 800;
					line-height: 1.2;
					padding-bottom: 15px;
				}
				p {
					margin-top: 0;
					margin-bottom: 30px;
					font-size: 27px;
					font-weight: 400;
					line-height: 1.7;
					color: #000;
				}
			}
		}
	}
`;

const Carousel = styled.div`
	&.hostjane-main__Carousel {
		position: relative;
		.hostjane-main__Carousel__arrow-right,
		.hostjane-main__Carousel__arrow-left {
			@media (max-width: 767px) {
				display: none;
			}
			cursor: pointer;
			align-items: center;
			background-color: white;
			border-radius: 50%;
			box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
			height: 80px;
			justify-content: center;
			line-height: 80px;
			margin-top: -40px;
			position: absolute;
			top: 50%;
			width: 80px;
			z-index: 1;
			display: flex;
			justify-content: center;
			svg {
				fill: rgb(217, 228, 235);
				height: 40px;
			}
			&:hover {
				background-color: #00c2e0;
				svg {
					fill: white;
				}
			}
		}

		.hostjane-main__Carousel__arrow-right {
			right: 0;
		}
		.hostjane-main__Carousel__arrow-left {
			left: 0;
		}
		.hostjane-main__Carousel__flame {
			@media (max-width: 1200px) {
				display: none;
			}
			background-image: url(${HostJane});
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			display: none;
			height: 188px;
			position: absolute;
			-webkit-transform: rotate(20deg);
			transform: rotate(20deg);
			-webkit-transition: all 0.3s;
			transition: all 0.3s;
			-webkit-transition-timing-function: ease-out;
			transition-timing-function: ease-out;
			width: 179px;
			z-index: 2;
			display: block;
			top: 40px;
			left: 400px;
			&.active-slide-1 {
				left: 790px;
				top: 40px;
			}
			&.active-slide-2 {
				left: 790px;
				top: 150px;
				transform: rotate(0);
			}
			&.active-slide-3 {
				left: 790px;
				top: 60px;
				transform: rotate(0);
			}
			&.active-slide-4 {
				left: 694px;
				top: -25px;
				transform: rotate(0);
			}
		}
	}
`;

const HostJaneSlider = ({
	title,
	subtitle,
	cards,
	slideMainTitle,
	membersAvatars,
	id,
}) => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};
	const [activeIndex, setActiveIndex] = useState(0);

	return (
		<Element name={id}>
			<HostJaneSliderWrapper
				id={id}
				className="hostjane-main__HostJaneSlider"
			>
				<div className="hostjane-main__HostJaneSlider__content">
					<div>
						<h3>{title}</h3>
						<p>{subtitle}</p>
					</div>
				</div>
				<Carousel className="hostjane-main__Carousel">
					<div
						className="hostjane-main__Carousel__arrow-right"
						onClick={() => {
							if (activeIndex !== 4) {
								const tempActiveIndex = activeIndex + 1;
								setActiveIndex(tempActiveIndex);
							} else {
								setActiveIndex(0);
							}
						}}
					>
						<ArrowRight />
					</div>
					<div
						className="hostjane-main__Carousel__arrow-left"
						onClick={() => {
							activeIndex !== 0
								? setActiveIndex(activeIndex => activeIndex - 1)
								: setActiveIndex(4);
						}}
					>
						<ArrowLeft />
					</div>
					<HostJaneSlide
						activeIndex={activeIndex}
						setActiveIndex={setActiveIndex}
						cards={cards}
						slideMainTitle={slideMainTitle}
						membersAvatars={membersAvatars}
					/>
					<div
						className={`hostjane-main__Carousel__flame active-slide-${activeIndex}`}
					/>
				</Carousel>
			</HostJaneSliderWrapper>
		</Element>
	);
};

export default HostJaneSlider;
